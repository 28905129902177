import React, { useEffect } from 'react';
import { Route, Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { createMemoryHistory } from 'history';

/**
 * Enables routing and allows to mount the story at a given path (useful for stories whose components depend on path params)
 * @param storyFn The actual story definition
 * @param mountPath Mounts the component at the specified path. (e.g. /contract/:contract-id)
 * @param initialRoutePath Navigates to the concrete path (e.g. /contract/1234)
 */
export const RouterWrapperWithOptions = (
    storyFn: () => React.ReactNode,
    mountPath?: string,
    initialRoutePath?: string,
) => {
    const history = createMemoryHistory();
    useEffect(() => {
        if (initialRoutePath) {
            history.push(initialRoutePath);
        }
    }, [initialRoutePath]);
    return (
        <Router history={history}>
            <LastLocationProvider>
                {mountPath ? <Route path={mountPath}>{storyFn()}</Route> : storyFn()}
            </LastLocationProvider>
        </Router>
    );
};

export const RouterWrapper = (storyFn: () => React.ReactNode) => {
    return RouterWrapperWithOptions(storyFn);
};
