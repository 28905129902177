import { select } from '@storybook/addon-knobs';
import React from 'react';

const supportedThemes = ['primary', 'alternative'];

export const BronsonPageWrapper = (storyFn: () => React.ReactNode) => {
    return (
        <div data-theme={select('Bronson Theme', supportedThemes, supportedThemes[0])}>
            <div className="o-page-wrap">{storyFn()}</div>
        </div>
    );
};
