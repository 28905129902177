/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import pl from 'flatpickr/dist/l10n/pl'
import it from 'flatpickr/dist/l10n/it'
import de from 'flatpickr/dist/l10n/de'
import cs from 'flatpickr/dist/l10n/cs'
import es from 'flatpickr/dist/l10n/es'
import fr from 'flatpickr/dist/l10n/fr'
import nl from 'flatpickr/dist/l10n/nl'
import pt from 'flatpickr/dist/l10n/pt'
import ja from 'flatpickr/dist/l10n/ja'
import sk from 'flatpickr/dist/l10n/sk'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { IMaskInput } from 'react-imask'
import IMask from 'imask'
import deprecated from '../../utils/deprecated'

const disabledButtonStyle = { pointerEvents: 'none' }

const getSeparator = (dateFormat) => {
  return dateFormat.substring(1, 2)
}

// specific for 'imask'
const createPattern = (dateFormat) => {
  const separator = getSeparator(dateFormat)
  return dateFormat.replaceAll(separator, `${separator}\``)
}

const getYmdPos = (dateFormat) => {
  const separator = getSeparator(dateFormat)
  return dateFormat.split(separator).reduce(
    (prev, curr, idx) => ({
      ...prev,
      [curr]: idx,
    }),
    {}
  )
}

const getPlaceholderChars = (dateFormat, placeholder) => {
  const separator = getSeparator(dateFormat)
  const { Y: yearPos, m: monthPos, d: dayPos } = getYmdPos(dateFormat)
  if (placeholder) {
    const splittedPlaceholder = placeholder.split(separator)
    return {
      yearPlaceholderChar: splittedPlaceholder[yearPos],
      monthPlaceholderChar: splittedPlaceholder[monthPos],
      dayPlaceholderChar: splittedPlaceholder[dayPos],
    }
  }
  return {
    yearPlaceholderChar: 'Y',
    monthPlaceholderChar: 'm',
    dayPlaceholderChar: 'd',
  }
}

const parseDate = (separator, yearPos, monthPos, dayPos) => (date) => {
  const yearMonthDay = date.split(separator)
  const parsedDate = new Date(yearMonthDay[yearPos], yearMonthDay[monthPos] - 1, yearMonthDay[dayPos])
  return Number.isNaN(parsedDate.getDate()) ? null : parsedDate
}

const formatDate = (dateFormat) => (date) => {
  let day = date.getDate()
  let month = date.getMonth() + 1
  const year = date.getFullYear()
  if (day < 10) day = `0${day}`
  if (month < 10) month = `0${month}`
  const { Y: yearPos, m: monthPos, d: dayPos } = getYmdPos(dateFormat)
  const separator = getSeparator(dateFormat)
  const newDate = []
  newDate[yearPos] = year
  newDate[monthPos] = month
  newDate[dayPos] = day
  return newDate.join(separator)
}

const setLocale = (locale) => {
  switch (locale) {
    case 'pl':
      return pl.pl
    case 'de':
      return de.de
    case 'it':
      return it.it
    case 'cs':
      return cs.cs
    case 'es':
      return es.es
    case 'fr':
      return fr.fr
    case 'nl':
      return nl.nl
    case 'pt':
      return pt.pt
    case 'ja':
      return ja.ja
    case 'sk':
      return sk.sk
    default:
      return null
  }
}

/**
 * Bronson DatePicker component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-datepicker.html
 *
 * @param {string} [value] - Date value
 * @param {string} [name] - Name attribute of input element
 * @param {string} [dateFormat='Y-m-d'] - Date format used by flatpickr, @see https://flatpickr.js.org/options/
 * @param {string} [placeholder] - placeholder shown when no date is selected
 * @param {string} [locale] - Sets locale of date picker labels, one of ['pl', 'de', 'it', 'cs', 'es', 'fr', 'nl', 'pt', 'ja', 'sk'] (ISO-639-1 code)
 * @param {(string | Date)} [minDate] - Minimum date that can be selected (inclusive), @see https://flatpickr.js.org/options/
 * @param {(string | Date)} [maxDate] - Maximum date that can be selected (inclusive), @see https://flatpickr.js.org/options/
 * @param {('single' | 'multiple' | 'range')} [mode='single'] - Flatpickr mode, 'single', 'multiple' or 'range' values are selectable
 * @param {boolean} [maskedInput] - masked input (react-imask) is used instead of HTML default input, only available for mode 'single', default: true
 * @param {boolean} [monthSelectorDropdown=false] - Month selector variant
 * @param {Array} [disableDays] - Convenience prop to disable certain days, e.g. ["2020-01-30", "2020-02-21", "2020-03-08", new Date(2020, 4, 9) ], [{ from: "2025-04-01", to: "2025-05-01" }], @see https://flatpickr.js.org/examples/#disabling-dates
 * @param {boolean} [noBackground=true] - By default the addon has a background. To remove the background and only show the addon text and/or icon.
 * @param {boolean} [disabled=false] - Disabled state
 * @param {function} [onBlur] - onBlur handler
 * @param {function} [onChange] - onChange handler
 * @param {boolean} [error=false] - Error state
 * @param {object} [flatpickrOptions] - Additional flatpickr options
 * @param {object} [flatpickrProps] - Additional props passed to Flatpickr React component
 * @param {string} [className=''] - Additional CSS classes on Flatpickr element (div with class c-input)
 * @param {string} [datePickerClassName=''] - Additional CSS classes on input element of Flatpickr (input with class c-input__input)
 * @param {string} [testId] - data-testid attribute on table
 * @param {boolean} [showMask] - deprecated, use 'maskedInput'
 * @param {boolean} [guide] - deprecated, use 'maskedInput'
 * @return {JSX.Element} - The ButtonContainer element.
 */
export function DatePicker({
  value: customValue,
  name,
  dateFormat = 'Y-m-d',
  placeholder,
  locale,
  minDate,
  maxDate,
  mode = 'single',
  maskedInput = true,
  monthSelectorDropdown = false,
  disableDays,
  noBackground = true,
  disabled = false,
  onBlur,
  onChange: customOnChange,
  error = false,
  flatpickrOptions,
  flatpickrProps,
  className,
  datePickerClassName,
  testId,
}) {
  const maskRef = useRef(null)
  const [date, setDate] = useState(customValue ?? '')

  const separator = useMemo(() => getSeparator(dateFormat), [dateFormat])
  const { Y: yearPos, m: monthPos, d: dayPos } = useMemo(() => getYmdPos(dateFormat), [dateFormat])
  const { yearPlaceholderChar, monthPlaceholderChar, dayPlaceholderChar } = useMemo(
    () => getPlaceholderChars(dateFormat, placeholder),
    [dateFormat, placeholder]
  )

  const mParseDate = useMemo(
    () => parseDate(separator, yearPos, monthPos, dayPos),
    [separator, yearPos, monthPos, dayPos]
  )

  const mFormatDate = useMemo(() => formatDate(dateFormat), [dateFormat])

  useEffect(() => {
    setDate(customValue ?? '')
  }, [customValue])

  const componentDatePickerWrapperClass = classNames('c-input', className).trim()

  const componentDatePickerClass = classNames(
    'c-input__input',
    {
      'is-error': error,
    },
    datePickerClassName
  ).trim()

  const componentDatePickerButtonClass = classNames('c-input__addon', {
    'c-input__addon--no-background': noBackground,
  }).trim()

  const componentDatePickerButtonIconClass = classNames('c-icon', 'c-icon--[semantic-calendar]').trim()

  const onChange = (e) => {
    const value = e.target === undefined ? e : e.target.value
    if (maskRef?.current?.maskRef?.updateValue) {
      maskRef.current.maskRef.updateValue()
    }
    if (value) {
      setDate(value)
      // eslint-disable-next-line no-unused-expressions
      customOnChange?.(value)
    } else {
      setDate(date)
    }
  }

  const renderInput = () => {
    return maskedInput && mode === 'single' ? (
      <IMaskInput
        className={componentDatePickerClass}
        name={name}
        ref={maskRef}
        data-input
        data-testid={testId}
        mask={Date}
        overwrite
        pattern={createPattern(dateFormat)}
        format={mFormatDate}
        blocks={{
          d: { mask: IMask.MaskedRange, placeholderChar: dayPlaceholderChar, from: 1, to: 31, maxLength: 2 },
          m: { mask: IMask.MaskedRange, placeholderChar: monthPlaceholderChar, from: 1, to: 12, maxLength: 2 },
          Y: { mask: IMask.MaskedRange, placeholderChar: yearPlaceholderChar, from: 1000, to: 2999, maxLength: 4 },
        }}
        autofix={false}
        lazy={false}
        parse={mParseDate}
        disabled={disabled}
      />
    ) : (
      <input
        data-testid={testId}
        name={name}
        className={componentDatePickerClass}
        placeholder={placeholder}
        disabled={disabled}
        data-input
      />
    )
  }

  const renderIcon = () => {
    return <i className={componentDatePickerButtonIconClass} aria-hidden="true" role="img" />
  }

  const renderButton = () => {
    return (
      <button
        className={componentDatePickerButtonClass}
        type="button"
        tabIndex="-1"
        aria-label="toggle datepicker"
        data-toggle
        style={disabled ? disabledButtonStyle : undefined}
      >
        {renderIcon()}
      </button>
    )
  }

  return (
    <div className="c-datepicker js-datepicker">
      <Flatpickr
        className={componentDatePickerWrapperClass}
        {...flatpickrProps}
        value={date}
        onChange={(_, value) => onChange(value)}
        onBlur={onBlur}
        /* onValueUpdate={(value) => {
          TODO: maskRef.current.maskRef.updateValue()
        }} */
        options={{
          wrap: true,
          allowInput: true,
          mode,
          locale: setLocale(locale),
          parseDate: (dateStr) => {
            const newDate = mParseDate(dateStr)
            if (newDate === null) {
              return mParseDate(date)
            }
            return newDate
          },
          dateFormat,
          maxDate,
          minDate,
          disabled: disableDays,
          nextArrow: '<i class="c-icon c-icon--[semantic-forward] c-icon-–simple"></i>',
          prevArrow: '<i class="c-icon c-icon--[semantic-back] c-icon-–simple"></i>',
          monthSelectorType: monthSelectorDropdown ? 'dropdown' : 'static',
          ...flatpickrOptions,
        }}
      >
        {renderInput()}
        {renderButton()}
      </Flatpickr>
    </div>
  )
}

DatePicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  locale: PropTypes.oneOf(['pl', 'de', 'it', 'cs', 'es', 'fr', 'nl', 'pt', 'ja', 'sk']), // ISO-639-1 code
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  mode: PropTypes.string,
  maskedInput: PropTypes.bool,
  monthSelectorDropdown: PropTypes.bool,
  disableDays: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object])), // convenience prop to disable certain days, e.g. ["2020-01-30", "2020-02-21", "2020-03-08", new Date(2020, 4, 9) ], [{ from: "2025-04-01", to: "2025-05-01" }]
  noBackground: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  flatpickrOptions: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  flatpickrProps: PropTypes.object,
  className: PropTypes.string,
  datePickerClassName: PropTypes.string,
  testId: PropTypes.string,

  showMask: deprecated(PropTypes.bool, 'Please use "maskedInput" instead.'),
  guide: deprecated(PropTypes.bool, 'Please use "maskedInput" instead.'),
}
