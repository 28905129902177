import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'
import { ReactLink, linkToType } from '../Link/Link'
import { IndicatorDot } from '../IndicatorDot/IndicatorDot'
import { Tooltip } from '../Tooltip/Tooltip'

/**
 * Default localizations for selected locales, which can be passed as locale prop.
 *
 * Note: A localization object can be passed instead with the localization prop.
 */
const localizationDefault = {
  de: {
    'month.01.name': 'Januar',
    'month.01.name.short': 'jan',
    'month.02.name': 'Februar',
    'month.02.name.short': 'feb',
    'month.03.name': 'März',
    'month.03.name.short': 'mär',
    'month.04.name': 'April',
    'month.04.name.short': 'apr',
    'month.05.name': 'Mai',
    'month.05.name.short': 'mai',
    'month.06.name': 'Juni',
    'month.06.name.short': 'jun',
    'month.07.name': 'Juli',
    'month.07.name.short': 'jul',
    'month.08.name': 'August',
    'month.08.name.short': 'aug',
    'month.09.name': 'September',
    'month.09.name.short': 'sep',
    'month.10.name': 'Oktober',
    'month.10.name.short': 'okt',
    'month.11.name': 'November',
    'month.11.name.short': 'nov',
    'month.12.name': 'Dezember',
    'month.12.name.short': 'dez',
  },
}

const InboxContext = React.createContext({})

/*

 * Generated React component.
 */

/**
 * Bronson Inbox component.
 * @see https://bronson.vwfs.tools/bluelabel/components/detail/bronson-inbox.html
 *
 * @param {React.ReactNodeArray} children
 * @param {string} [columns=['colDate', 'colDownload', 'colSubject', 'colDelete']]
 * @param {function} [dateFormatter]
 * @param {string} [deleteButtonLabel='Delete']
 * @param {boolean} [deleteShowLabel=false]
 * @param {string} [downloadButtonLabel='downloadButtonLabel']
 * @param {boolean} [downloadShowLabel=false]
 * @param {React.ReactNodeArray} [filterElements]
 * @param {React.ReactNode} [filterTitle]
 * @param {string} [locale]
 * @param {object} [localization]
 * @param {object} [tableColumnClassNames]
 * @param {React.ReactNodeArray} [tableHeader]
 * @param {object} [tableHeaderClassNames]
 * @param {string} [testId]
 * @param {object} [otherProps]
 * @return {JSX.Element} - The Inbox component.
 * @constructor
 */
export function Inbox({
  children,
  columns = ['colDate', 'colDownload', 'colSubject', 'colDelete'],
  dateFormatter,
  deleteButtonLabel = 'Delete',
  deleteShowLabel = false,
  downloadButtonLabel = 'Download',
  downloadShowLabel = false,
  filterElements,
  filterTitle,
  locale,
  localization,
  tableColumnClassNames,
  tableHeader,
  tableHeaderClassNames,
  testId,
  ...otherProps /* in <div> tag */
}) {
  const contextValue = {
    localization: localization || localizationDefault[locale],
    tableHeader,
    tableHeaderClassNames,
    tableColumnClassNames,
    columns,
    dateFormatter,
    downloadButtonLabel,
    downloadShowLabel,
    deleteButtonLabel,
    deleteShowLabel,
  }

  // generated main result
  return (
    <>
      {filterTitle || filterElements ? (
        <div className="c-inbox-filter">
          <h3 className="c-inbox-filter__title">{filterTitle}</h3>
          <div className="c-inbox-filter__body">
            <div className="o-inline-group o-inline-group--small o-inline-group--stretch">{filterElements}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="c-inbox-table" data-testid={testId} {...otherProps}>
        <InboxContext.Provider value={contextValue}>
          {children /* Use 'Inbox.Section' component. */}
        </InboxContext.Provider>
      </div>
    </>
  )
}

Inbox.propTypes = {
  filterTitle: PropTypes.node,
  filterElements: PropTypes.arrayOf(PropTypes.node), // Bronson template: 'input'. Use 'Input' component.
  tableHeader: PropTypes.arrayOf(PropTypes.node),
  tableHeaderClassNames: PropTypes.object, // use to set utility classes to <th> elements, expected is a object with column name as keys, e.g. { 'colDownload': 'u-ws-nowrap' }
  tableColumnClassNames: PropTypes.object, // use to set utility classes to columns <td> elements, expected is a object with column name as keys, e.g. { 'colDownload': 'u-ws-nowrap' }
  columns: PropTypes.arrayOf(PropTypes.string), // use to define additional columns and/or redefine order (default is ['colDate', 'colDownload', 'colSubject','colDelete'])
  locale: PropTypes.string,
  localization: PropTypes.object, // can be used to overwrite default localizations derived from locale.
  dateFormatter: PropTypes.func, // custom formatter for the date column, as (day: string, month: string, year: string): string
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'year'. Use 'Inbox.Section' component.
  downloadButtonLabel: PropTypes.string, // custom label for download button
  downloadShowLabel: PropTypes.bool, // show label for download button in addition to icon
  deleteButtonLabel: PropTypes.string, // custom label for delete button
  deleteShowLabel: PropTypes.bool, // show label for delete button in addition to icon
}

/**
 * Bronson FilterElement component (nested).
 * @param {string} [children]
 * @param {string} [testId]
 * @param {object} [otherProps]
 * @return {JSX.Element}
 * @constructor
 */
function FilterElement({ children, testId, ...otherProps /* in <div> tag */ }) {
  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className="o-inline-group__item">
      {children}
    </div>
  )
}

FilterElement.propTypes = {
  children: PropTypes.node, // Bronson template: 'include'.
  testId: PropTypes.string, // Added for data-testid attribute.
}

FilterElement.displayName = 'Inbox.FilterElement'
Inbox.FilterElement = FilterElement

/**
 * Bronson Section component (nested).
 *
 * @param {React.ReactNodeArray} [children]
 * @param {boolean} [showTableHeader]
 * @param {boolean} [showYear]
 * @param {string} [year]
 * @param {string} [testId]
 * @param {object} [otherProps]
 * @return {JSX.Element}
 * @constructor
 */
function Section({ children, showTableHeader, showYear, year, testId, ...otherProps /* in no tag */ }) {
  const context = React.useContext(InboxContext)

  const thClassNames = (colName) =>
    classNames('c-inbox-table__th', context.tableHeaderClassNames && context.tableHeaderClassNames[colName]).trim()

  // generated
  function renderIfShowYear() {
    if (showYear) {
      return <h4 className="c-inbox-table__subheading">{year}</h4>
    }
    return null
  }

  // generated
  function renderIfShowTableHeader() {
    if (showTableHeader && context.tableHeader) {
      return (
        <thead>
          <tr>
            {context.tableHeader.map((item, index) => (
              <th
                scope="col"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={thClassNames(context.columns[index])}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
      )
    }
    return null
  }

  // generated main result
  return (
    <>
      {renderIfShowYear()}
      <div className="c-table-wrapper" data-testid={testId} {...otherProps}>
        <table>
          {renderIfShowTableHeader()}
          <tbody className="c-inbox-table__body">{children /* Use 'Inbox.Message' component. */}</tbody>
        </table>
      </div>
    </>
  )
}

Section.propTypes = {
  children: PropTypes.node, // Bronson template: 'message'. Use 'Inbox.Message' component.
  showTableHeader: PropTypes.bool, // Bronson template: 'first'.
  showYear: PropTypes.bool, // Bronson template: 'show-year'.
  testId: PropTypes.string, // Added for data-testid attribute.
  year: PropTypes.string, // Bronson template: 'year'.
}

Section.displayName = 'Inbox.Section'
Inbox.Section = Section

/**
 * Bronson Message component (nested).
 *
 * @param {React.ReactNode} [children]
 * @param {string} [day]
 * @param {string} [download]
 * @param {string} [extraInfo]
 * @param {string} [messageKey]
 * @param {string} [month]
 * @param {string} [onDeleteClick]
 * @param {string} [onDownloadClick]
 * @param {string} [onItemClick]
 * @param {string} [read]
 * @param {string} [to]
 * @param {string} [tooltipContent='Unread item']
 * @param {string} [year]
 * @param {string} [testId]
 * @param {string} [otherProps]
 * @return {JSX.Element}
 * @constructor
 */
function Message({
  children,
  day,
  download,
  extraInfo,
  messageKey,
  month,
  onDeleteClick,
  onDownloadClick,
  onItemClick,
  read,
  to,
  tooltipContent = 'Unread item',
  year,
  testId,
  ...otherProps /* in <tr> tag */
}) {
  const context = React.useContext(InboxContext)

  const tdClassNames = (classes, colName) =>
    classNames(
      'c-inbox-table__td',
      classes,
      context.tableColumnClassNames && context.tableColumnClassNames[colName]
    ).trim()

  const restProps = { ...otherProps }
  context.columns.forEach((col) => delete restProps[col])

  const monthName = context.localization ? context.localization[`month.${month}.name`] : null
  const monthNameShort = context.localization ? context.localization[`month.${month}.name.short`] : null
  const dateFormatter = context.dateFormatter || ((dayName) => `${dayName}. ${monthName}`)

  // generated
  const trClassNameList = classNames({
    'c-inbox-table__tr ': true,
    'is-unread ': !read,
  }).trim()

  // generated
  function renderIfDownload() {
    if (download) {
      return (
        <Button
          element="a"
          icon="semantic-download"
          secondary
          hiddenLabel={!context.downloadShowLabel}
          className="c-table__btn"
          onClick={onDownloadClick}
          title={context.downloadButtonLabel}
        >
          {context.downloadButtonLabel}
        </Button>
      )
    }
    return null
  }

  // generated
  function renderIfExtraInfo() {
    if (extraInfo) {
      return <p className="c-inbox-table__extra-info">{extraInfo}</p>
    }
    return null
  }

  // generated
  function renderUnlessDownload() {
    if (!download) {
      return (
        <Button
          icon="semantic-delete"
          secondary
          className="c-table__btn"
          hiddenLabel={!context.deleteShowLabel}
          onClick={onDeleteClick}
          title={context.deleteButtonLabel}
        >
          {context.deleteButtonLabel}
        </Button>
      )
    }
    return null
  }

  const renderColDate = (colName) => (
    <td className={tdClassNames('c-inbox-table__td--date', colName)} key={`${messageKey}--date`}>
      <time
        className="c-inbox-table__date"
        data-inbox-day={day}
        data-inbox-month={monthNameShort || month}
        dateTime={`${year}-${month}-${day}`}
        title={`${day}. ${monthName || month} ${year}`}
      >
        {dateFormatter(day, month, year)}
      </time>
    </td>
  )

  const renderColDownload = (colName) => (
    <td className={tdClassNames('c-inbox-table__td--action', colName)} key={`${messageKey}--download`}>
      {renderIfDownload()}
    </td>
  )

  const renderColDelete = (colName) => (
    <td className={tdClassNames('c-inbox-table__td--action', colName)} key={`${messageKey}--delete`}>
      {renderUnlessDownload()}
    </td>
  )

  const renderColSubject = (colName) => (
    <td className={tdClassNames('c-inbox-table__td--subject', colName)} key={`${messageKey}--subject`}>
      {renderIfExtraInfo()}
      <div className="c-inbox-table__td__subject">
        {!read && (
          <IndicatorDot
            className="c-inbox-table__status-indicator"
            status="unread"
            tooltip={
              <Tooltip
                className="c-indicator-dot__dot"
                content={tooltipContent}
                otherProps={{ 'aria-label': tooltipContent }}
              >
                {' '}
              </Tooltip>
            }
          />
        )}
        <ReactLink onClick={onItemClick} to={to || '#'} className="c-inbox-table__link">
          {children}
        </ReactLink>
      </div>
    </td>
  )

  // generated main result
  return (
    <tr {...restProps} data-testid={testId} className={trClassNameList}>
      {context.columns.map((colName) => {
        switch (colName) {
          case 'colDate':
            return renderColDate(colName)
          case 'colDownload':
            return renderColDownload(colName)
          case 'colSubject':
            return renderColSubject(colName)
          case 'colDelete':
            return renderColDelete(colName)
          default:
            return otherProps[colName] ? (
              <td className={tdClassNames('u-hide@s', colName)} key={`${messageKey}--${colName}`}>
                {otherProps[colName]}
              </td>
            ) : null
        }
      })}
    </tr>
  )
}

Message.propTypes = {
  children: PropTypes.node, // Bronson template: 'title'.
  day: PropTypes.string, // Bronson template: 'day'.
  download: PropTypes.bool, // Bronson template: 'download'.
  extraInfo: PropTypes.node, // Bronson template: 'extra-info'.
  messageKey: PropTypes.string, // used to set Reacts 'key' prop internally
  month: PropTypes.string, // Bronson template: 'month'.
  onDeleteClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  onItemClick: PropTypes.func,
  read: PropTypes.bool, // Bronson template: 'read'.
  to: linkToType,
  year: PropTypes.string, // Bronson template: 'year'.
  /* Convenience prop */
  tooltipContent: PropTypes.string, // Used to set a meaningful unread message for inbox items
  testId: PropTypes.string, // Added for data-testid attribute.
}

Message.displayName = 'Inbox.Message'
Inbox.Message = Message
